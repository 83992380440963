import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, TextField, AppBar, Toolbar, Link, useMediaQuery, Grid } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { signInWithEmailAndPassword, sendPasswordResetEmail, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { auth } from './firebase';
import { useNavigate } from 'react-router-dom';
import NavigationBar from './NavigationBar';

const Root = styled('div')(({ isMobile }) => ({
    position: 'relative',
    overflow: 'hidden',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#141414', // Set background color for mobile
}));

const StyledAppBar = styled(AppBar)({
    backgroundColor: 'rgba(20, 20, 20, 0.8)',
    borderBottom: '1px solid #e6e6e6',
    zIndex: 10,
    position: 'fixed',
    top: 0,
    width: '100%',
});

const Logo = styled('img')({
    height: 'auto',
    width: '20%',
    maxWidth: '100px',
    minWidth: '50px',
});

const VideoBackground = styled('video')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: -1, // Keep video behind all elements
});

const Overlay = styled('div')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 1, // Overlay over the video to darken it
});

const StyledContainer = styled(Container)({
    textAlign: 'center',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    zIndex: 2,
    marginTop: '4rem',
});

const FormContainer = styled(Container)({
    backgroundColor: 'rgba(51, 51, 51, 0.8)',
    padding: '2rem',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
    marginTop: '2rem',
    zIndex: 2,
});

const ActionButton = styled(Button)({
    backgroundColor: '#12c099',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#0f9f85',
    },
    marginTop: '1rem',
});

const PhoneInputWrapper = styled('div')({
    margin: '8px 0',
    '& .react-tel-input .form-control': {
        width: '100%',
        backgroundColor: 'rgba(51, 51, 51, 0.8)',
        color: '#fff',
        border: 'none',
        borderBottom: '1px solid #fff',
    },
    '& .react-tel-input .flag-dropdown': {
        backgroundColor: 'rgba(51, 51, 51, 0.8)',
        border: 'none',
    },
    '& .react-tel-input .selected-flag': {
        backgroundColor: 'rgba(51, 51, 51, 0.8)',
    },
    '& .react-tel-input .form-control:focus': {
        borderBottom: '2px solid #12c099',
    },
});

const Footer = styled('footer')(({ theme }) => ({
    marginTop: '2rem',
    color: '#b3b3b3',
    zIndex: 2,
    width: '100%',
}));

const FooterLink = styled(Typography)(({ theme }) => ({
    color: '#12c099',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'none',
        color: '#0f9f85',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.8rem',
    },
}));

const Login = ({ handleLogin }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const [identifier, setIdentifier] = useState('');
    const [password, setPassword] = useState('');
    const [useSignInCode, setUseSignInCode] = useState(false);
    const [isPhone, setIsPhone] = useState(false);
    const [signInCodeSent, setSignInCodeSent] = useState(false);
    const [signInCode, setSignInCode] = useState('');
    const [confirmationResult, setConfirmationResult] = useState(null);

    useEffect(() => {
        if (isPhone && useSignInCode && !window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
                size: 'invisible',
                callback: (response) => {
                    onSignInSubmit();
                },
                'expired-callback': () => {},
            }, auth);
        }
    }, [isPhone, useSignInCode]);

    const handleLoginClick = async () => {
        if (useSignInCode) {
            if (signInCodeSent) {
                try {
                    const result = await confirmationResult.confirm(signInCode);
                    handleLogin(); // Call handleLogin after successful login
                    navigate('/dashboard');
                } catch (error) {
                    console.error('Error verifying code:', error);
                }
            } else {
                if (isPhone) {
                    try {
                        const appVerifier = window.recaptchaVerifier;
                        const confirmationResult = await signInWithPhoneNumber(auth, `+${identifier}`, appVerifier);
                        setConfirmationResult(confirmationResult);
                        setSignInCodeSent(true);
                    } catch (error) {
                        console.error('Error sending sign-in code:', error);
                    }
                } else {
                    try {
                        await sendPasswordResetEmail(auth, identifier);
                        setSignInCodeSent(true);
                    } catch (error) {
                        console.error('Error sending sign-in code:', error);
                    }
                }
            }
        } else {
            try {
                const userCredential = await signInWithEmailAndPassword(auth, identifier, password);
                handleLogin(); // Call handleLogin after successful login
                navigate('/dashboard');
            } catch (error) {
                console.error('Error logging in:', error);
            }
        }
    };

    const handleRegister = () => {
        window.location.href = '/choose-plan';
    };

    const handleForgotPassword = async () => {
        try {
            await sendPasswordResetEmail(auth, identifier);
        } catch (error) {
            console.error('Error sending password reset email:', error);
        }
    };

    const toggleSignInMethod = () => {
        setUseSignInCode(!useSignInCode);
        setSignInCodeSent(false);
    };

    const handleIdentifierChange = (value) => {
        if (/^\d+$/.test(value)) {
            setIsPhone(true);
            setIdentifier(value);
        } else {
            setIsPhone(false);
            setIdentifier(value);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleLoginClick();
        }
    };

    return (
        <Root isMobile={isMobile}>
            <NavigationBar isLoggedIn={false} showDashboardLink={false} handleLogout={() => {}} />

            {/* Only render video if not on mobile */}
            {!isMobile && (
                <>
                    <VideoBackground autoPlay loop muted>
                        <source src="https://www-media.marsreel.com/wp-content/uploads/2019/03/19235924/MARS-REEL_SPLASH_V04C.mp4" type="video/mp4" />
                    </VideoBackground>
                    <Overlay />
                </>
            )}



            <StyledContainer maxWidth="sm">
                <Typography variant="h4" gutterBottom>
                    {useSignInCode ? (signInCodeSent ? 'Verify Sign-In Code' : 'Sign In with Code') : 'Login'}
                </Typography>
                <FormContainer>
                    {!signInCodeSent ? (
                        <>
                            {isPhone ? (
                                <PhoneInputWrapper>
                                    <PhoneInput
                                        country={'us'}
                                        value={identifier}
                                        onChange={handleIdentifierChange}
                                        inputStyle={{
                                            width: '100%',
                                            backgroundColor: 'rgba(51, 51, 51, 0.8)',
                                            color: '#fff',
                                            border: 'none',
                                            borderBottom: '1px solid #fff',
                                        }}
                                    />
                                </PhoneInputWrapper>
                            ) : (
                                <TextField
                                    label="Email or Phone Number"
                                    value={identifier}
                                    onChange={(e) => handleIdentifierChange(e.target.value)}
                                    fullWidth
                                    margin="dense"
                                    variant="filled"
                                    InputLabelProps={{
                                        style: { color: '#fff' },
                                    }}
                                    InputProps={{
                                        style: { color: '#fff' },
                                    }}
                                />
                            )}
                            {!useSignInCode && (
                                <TextField
                                    label="Password"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onKeyPress={handleKeyPress}
                                    fullWidth
                                    margin="dense"
                                    variant="filled"
                                    InputLabelProps={{
                                        style: { color: '#fff' },
                                    }}
                                    InputProps={{
                                        style: { color: '#fff' },
                                    }}
                                />
                            )}
                        </>
                    ) : (
                        <TextField
                            label="Enter Sign-In Code"
                            value={signInCode}
                            onChange={(e) => setSignInCode(e.target.value)}
                            fullWidth
                            margin="dense"
                            variant="filled"
                            InputLabelProps={{
                                style: { color: '#fff' },
                            }}
                            InputProps={{
                                style: { color: '#fff' },
                            }}
                        />
                    )}
                    <ActionButton id="sign-in-button" fullWidth variant="contained" onClick={handleLoginClick}>
                        {useSignInCode && signInCodeSent ? 'Verify' : (useSignInCode ? 'Send Sign-In Code' : 'Login')}
                    </ActionButton>
                    <Button color="inherit" onClick={toggleSignInMethod}>
                        {useSignInCode ? 'Use Password' : 'Use a sign-in code'}
                    </Button>
                </FormContainer>
                <Typography variant="body2" style={{ marginTop: '1rem' }}>
                    <Link href="#" onClick={handleForgotPassword} style={{ color: '#12c099' }}>
                        Forgot password?
                    </Link>
                </Typography>
                <Typography variant="body2" style={{ marginTop: '1rem' }}>
                    Don't have an account?{' '}
                    <Link href="#" onClick={handleRegister} style={{ color: '#12c099' }}>
                        Create an account
                    </Link>
                </Typography>
            </StyledContainer>

            <Footer>
                <StyledContainer maxWidth="md">
                    <Typography variant="body2">
                        Questions? Email us: support@marsreel.com
                    </Typography>
                    <Grid container spacing={2} justifyContent="center" alignItems="center" flexWrap="wrap" style={{ marginTop: '1rem' }}>
                        <Grid item>
                            <FooterLink variant="body2" component="a" href="/faq">FAQ</FooterLink>
                        </Grid>
                        <Grid item>
                            <FooterLink variant="body2" component="a" href="/help-center">Help Center</FooterLink>
                        </Grid>
                        <Grid item>
                            <FooterLink variant="body2" component="a" href="/terms-of-use">Terms of Use</FooterLink>
                        </Grid>
                        <Grid item>
                            <FooterLink variant="body2" component="a" href="/privacy">Privacy</FooterLink>
                        </Grid>
                        <Grid item>
                            <FooterLink variant="body2" component="a" href="/company-policy">Company Policy</FooterLink>
                        </Grid>
                        <Grid item>
                            <FooterLink variant="body2" component="a" href="/engineering">Engineering & Data Science</FooterLink>
                        </Grid>
                        <Grid item>
                            <FooterLink variant="body2" component="a" href="https://affiliate.marsreel.com">Ambassador Program</FooterLink>
                        </Grid>
                    </Grid>
                </StyledContainer>
            </Footer>
        </Root>
    );
};

export default Login;
