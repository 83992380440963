// components/EngineeringAndDataScience.js
import React from 'react';
import { Container, Typography, Box, Divider, Button, Grid, Card, CardContent } from '@mui/material';
import { styled } from '@mui/system';

const Root = styled('div')({
    backgroundColor: '#141414',
    color: '#e0e0e0',
    minHeight: '100vh',  // Ensures it takes the full height of the screen
    display: 'flex',
    flexDirection: 'column', // Makes content stack in a column layout
    justifyContent: 'flex-start', // Keeps content aligned at the top
    alignItems: 'center',
    padding: '3rem 1.5rem',
    animation: 'fadeIn 1s ease-in-out',
    '@keyframes fadeIn': {
        '0%': { opacity: 0 },
        '100%': { opacity: 1 },
    }
});

const Section = styled(Box)({
    marginBottom: '3rem',
    padding: '1rem',
    backgroundColor: '#1f1f1f',
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
});

const HeaderContainer = styled(Box)({
    marginBottom: '4rem',
    marginTop: '2rem',
    textAlign: 'center',
});

const Header = styled(Typography)({
    fontSize: '3rem',
    fontWeight: '600',
    textAlign: 'center',
    marginBottom: '2rem',
    color: '#12c099',
    '@media (max-width: 600px)': {
        fontSize: '2.5rem',
    },
});

const SubHeader = styled(Typography)({
    marginBottom: '3rem',
    fontSize: '1.2rem',
    textAlign: 'center',
    color: '#b0b0b0',
    maxWidth: '800px',
    margin: '0 auto',
    lineHeight: '1.8rem',
});

const SectionTitle = styled(Typography)({
    fontSize: '1.75rem',
    fontWeight: '600',
    marginBottom: '1rem',
    color: '#ffffff',
    borderBottom: '2px solid #12c099',
    paddingBottom: '0.5rem',
    maxWidth: 'fit-content',
});

const SectionContent = styled(Typography)({
    fontSize: '1rem',
    lineHeight: '1.75rem',
    color: '#c0c0c0',
    marginBottom: '1.5rem',
});

const DividerLine = styled(Divider)({
    backgroundColor: '#303030',
    margin: '3rem 0',
});

const SummaryParagraph = styled(Typography)({
    marginTop: '4rem',
    marginBottom: '6rem',  // Add extra space before the footer
    fontSize: '1rem',
    textAlign: 'left',
    color: '#c0c0c0',
    '@media (max-width: 600px)': {
        fontSize: '0.9rem',
    },
});


const ComingSoonHeader = styled(Typography)({
    fontSize: '2rem',
    fontWeight: '600',
    marginTop: '4rem',
    textAlign: 'center',
    color: '#ffffff',
});

const CardStyled = styled(Card)({
    backgroundColor: '#1c1c1c',
    borderRadius: '8px',
    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.3s',
    '&:hover': {
        transform: 'translateY(-5px)',
    }
});

const ButtonStyled = styled(Button)({
    backgroundColor: '#12c099',
    color: '#141414',
    '&:hover': {
        backgroundColor: '#0e8f7b',
    },
    marginTop: '1rem',
    padding: '0.5rem 2rem',
});

const Footer = styled(Box)({
    width: '100%',
    padding: '2rem 0',  // Increased padding
    textAlign: 'center',
    fontSize: '0.9rem',
    color: '#b0b0b0',
    borderTop: '1px solid #303030',
    backgroundColor: '#141414',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 'auto',
});



const EngineeringAndDataScience = () => {
    return (
        <Root>
            <Container maxWidth="lg">
                <HeaderContainer>
                    <Header>Engineering & Data Science</Header>
                    <SubHeader>
                        Discover how Mars Reel® is redefining sports media and youth culture with AI-driven automation, cutting-edge
                        engineering, and data science that powers the next generation of digital experiences.
                    </SubHeader>
                </HeaderContainer>

                <Section>
                    <SectionTitle>Our Engineering Principles</SectionTitle>
                    <SectionContent>
                        At Mars Reel®, our engineering and design philosophy is built on five core principles:
                        speed, intelligence, scalability, user experience, and innovation. Every system we build is designed for
                        real-time performance, AI-driven automation, and seamless user interaction to push the boundaries of
                        sports media technology.
                    </SectionContent>
                    <Grid container spacing={4}>
                        {[
                            { title: 'Speed', desc: 'We prioritize rapid iteration, deploying updates quickly to stay ahead in a fast-moving industry.' },
                            { title: 'Intelligence', desc: 'Our AI and data models continuously optimize engagement, content recommendations, and automation.' },
                            { title: 'Scalability', desc: 'Built for millions, our architecture ensures high availability, performance, and seamless growth.' },
                            { title: 'User Experience', desc: 'We craft intuitive, immersive interactions that make consuming sports content effortless and engaging.' },
                            { title: 'Innovation', desc: 'We push the boundaries of technology, exploring new ways to enhance digital sports storytelling and real-world experiences.' }
                        ].map((principle, index) => (
                            <Grid item xs={12} md={4} key={index}>
                                <CardStyled>
                                    <CardContent>
                                        <Typography variant="h6" color="#12c099">{principle.title}</Typography>
                                        <Typography variant="body2" color="#c0c0c0">
                                            {principle.desc}
                                        </Typography>
                                    </CardContent>
                                </CardStyled>
                            </Grid>
                        ))}
                    </Grid>
                </Section>

                <DividerLine />

                <Section>
                    <SectionTitle>Innovation in Action</SectionTitle>
                    <SectionContent>
                        Our proprietary SPACE platform is revolutionizing youth sports and media with advanced AI, machine learning, predictive analytics,
                        and real-time automation. SPACE powers everything from on-the-ground event coordination to personalized highlight reels,
                        ensuring every experience is optimized for performance and engagement—both in-person and online.
                    </SectionContent>
                    <ButtonStyled variant="contained" href="/careers">
                        Join Our Engineering Team
                    </ButtonStyled>
                </Section>

                <DividerLine />

                <Section>
                    <SectionTitle>Meet Our Engineering Advisory Board</SectionTitle>
                    <SectionContent>
                        Mars Reel® is guided by some of the industry’s top minds in technology, engineering, and product development.
                        Our advisory board brings expertise from leading companies like Airbnb, Meta, GoPro, and Akamai, ensuring that we
                        stay ahead in the ever-evolving tech landscape.
                    </SectionContent>
                    <Grid container spacing={4}>
                        {[
                            { name: 'Mike Curtis', role: 'Investor & Advisor, Former VP of Engineering at Airbnb, Facebook, Yahoo' },
                            { name: 'Maryanna Quigless', role: 'Investor & Advisor, Director, Product Management at Meta Inc.' },
                            { name: 'Tim Flack', role: 'Advisor, Former Engineer at Evasyst, Quickfire Networks (Facebook), GoPro, Akamai' }
                        ].map((leader, index) => (
                            <Grid item xs={12} md={4} key={index}>
                                <CardStyled>
                                    <CardContent>
                                        <Typography variant="h6" color="#12c099">{leader.name}</Typography>
                                        <Typography variant="body2" color="#c0c0c0">{leader.role}</Typography>
                                    </CardContent>
                                </CardStyled>
                            </Grid>
                        ))}
                    </Grid>
                </Section>

                <DividerLine />

                <ComingSoonHeader>The Vision for SPACE</ComingSoonHeader>

                <SummaryParagraph>
                    SPACE is more than a platform—it’s a blueprint for the future of sports media and youth sports technology.
                    Through ongoing development, iterative enhancements, and AI-driven insights, we are transforming the way athletes,
                    brands, and fans connect. By seamlessly blending the digital and physical worlds through robust data science,
                    innovative AI, and cutting-edge automation, SPACE continues to set new benchmarks in sports technology and real-world experiences.
                </SummaryParagraph>

            </Container>
            <Footer>
                © 2025 Mars Reel Media Corp. All rights reserved.
            </Footer>
        </Root>
    );
};

export default EngineeringAndDataScience;
