import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Button, Card, CardContent, CardActions, Grid, AppBar, Toolbar, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Root = styled('div')(({ isMobile }) => ({
    position: 'relative',
    overflow: 'hidden',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: isMobile ? '#141414' : 'transparent', // Use black background for mobile
}));

const StyledAppBar = styled(AppBar)({
    backgroundColor: 'rgba(20, 20, 20, 0.8)',
    borderBottom: '1px solid #e6e6e6',
    zIndex: 10,
    position: 'fixed',
    top: 0,
    width: '100%',
});

const Logo = styled('img')({
    flexGrow: 1,
    height: 'auto',
    width: '20%',
    maxWidth: '100px',
    minWidth: '50px',
});

const VideoBackground = styled('video')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: -1,
});

const Overlay = styled('div')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)', // Brighter tint
    zIndex: 1,
});

const StyledContainer = styled(Container)({
    textAlign: 'center',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    zIndex: 2,
    marginTop: '4rem', // Adjusted for fixed header
});

const StyledCard = styled(Card)({
    backgroundColor: 'rgba(51, 51, 51, 0.8)',
    color: '#fff',
    borderRadius: 8,
    padding: '1rem',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
});

const CardTitle = styled(Typography)({
    fontSize: '1.5rem',
    fontWeight: 'bold',
});

const CardPrice = styled(Typography)({
    fontSize: '2rem',
    margin: '1rem 0',
});

const CardDescription = styled(Typography)({
    color: '#b3b3b3',
});

const ActionButton = styled(Button)({
    backgroundColor: '#12c099', // Updated green color
    color: '#fff',
    '&:hover': {
        backgroundColor: '#0f9f85', // Darker green on hover
    },
});

const LearnMoreLink = styled('a')({
    color: '#12c099',
    textDecoration: 'none',
    '&:hover': {
        color: '#0f9f85', // Darker green on hover
    },
});

const Footer = styled('footer')({
    marginTop: '2rem',
    color: '#b3b3b3',
    zIndex: 2,
    width: '100%', // Added for responsiveness
});

const FooterLink = styled(Typography)(({ theme }) => ({
    color: '#12c099',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'none',
        color: '#0f9f85', // Darker green on hover
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.8rem', // Smaller font size on mobile
    },
}));

const StepIndicator = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1rem',
    zIndex: 2,
});

const FreeSessionText = styled('span')({
    color: '#12c099',
    textDecoration: 'none',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
    zIndex: 2,
    '&:hover': {
        color: '#0f9f85', // Darker green on hover
    },
});


const ChoosePlan = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handlePlanSelection = (plan) => {
        navigate('/create-account', { state: { selectedPlan: plan } });
    };

    return (
        <Root isMobile={isMobile}>
            {!isMobile && (
                <>
                    <VideoBackground autoPlay loop muted>
                        <source src="https://www-media.marsreel.com/wp-content/uploads/2019/03/19235924/MARS-REEL_SPLASH_V04C.mp4" type="video/mp4" />
                    </VideoBackground>
                    <Overlay />
                </>
            )}



            <StyledContainer maxWidth="md">
                <StepIndicator>
                    <CheckCircleIcon style={{ marginRight: '0.5rem', color: '#12c099', fontSize: '2rem' }} />
                    <Typography variant="h5" style={{ fontSize: '2rem' }}>Step 1 of 3</Typography>
                </StepIndicator>
                <Typography variant="h4" gutterBottom>
                    Choose Your Plan
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Select the plan that's right for you.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    No credit card required.
                </Typography>

                <Grid container spacing={isMobile ? 2 : 4} justifyContent="center" style={{ marginTop: '2rem' }}>
                    <Grid item xs={12} sm={8} md={5}>
                        <StyledCard>
                            <CardContent>
                                <CardTitle variant="h6" gutterBottom>
                                    Pay As You Go
                                </CardTitle>
                                {/* Removed price */}
                                <FreeSessionText>
                                    First Session is Free
                                </FreeSessionText>
                            </CardContent>
                            <CardActions>
                                <ActionButton fullWidth variant="contained" onClick={() => handlePlanSelection('Pay As You Go')}>
                                    Choose Plan
                                </ActionButton>
                            </CardActions>
                        </StyledCard>
                    </Grid>

                    <Grid item xs={12} sm={8} md={5}>
                        <StyledCard>
                            <CardContent>
                                <CardTitle variant="h6" gutterBottom>
                                    Recurring
                                </CardTitle>
                                {/* Removed price */}
                                <FreeSessionText>
                                    First Session is Free
                                </FreeSessionText>
                            </CardContent>
                            <CardActions>
                                <ActionButton fullWidth variant="contained" onClick={() => handlePlanSelection('Recurring')}>
                                    Choose Plan
                                </ActionButton>
                            </CardActions>
                        </StyledCard>
                    </Grid>
                </Grid>
            </StyledContainer>

            <Footer>
                <StyledContainer maxWidth="md">
                    <Typography variant="body2">
                        Questions? Email us: academy@marsreel.com
                    </Typography>
                    <Grid container spacing={2} justifyContent="center" alignItems="center" flexWrap="wrap" style={{ marginTop: '1rem' }}>
                        <Grid item>
                            <FooterLink variant="body2" component="a" href="/faq">FAQ</FooterLink>
                        </Grid>
                        <Grid item>
                            <FooterLink variant="body2" component="a" href="/help-center">Help Center</FooterLink>
                        </Grid>
                        <Grid item>
                            <FooterLink variant="body2" component="a" href="/terms-of-use">Terms of Use</FooterLink>
                        </Grid>
                        <Grid item>
                            <FooterLink variant="body2" component="a" href="/privacy">Privacy</FooterLink>
                        </Grid>
                        <Grid item>
                            <FooterLink variant="body2" component="a" href="/company-policy">Company Policy</FooterLink>
                        </Grid>
                        <Grid item>
                            <FooterLink variant="body2" component="a" href="/engineering">Engineering & Data Science</FooterLink>
                        </Grid>
                        <Grid item>
                            <FooterLink variant="body2" component="a" href="https://affiliate.marsreel.com">Ambassador Program</FooterLink>
                        </Grid>
                    </Grid>
                </StyledContainer>
            </Footer>
        </Root>
    );
};

export default ChoosePlan;
