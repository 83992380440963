import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Button, Box } from '@mui/material';
import { styled } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NavigationBar from './NavigationBar';
import { useAuth } from './context/AuthContext'; // Import Auth Context

const Root = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '100vh',
    textAlign: 'center',
    backgroundColor: '#141414',
    color: '#fff',
    paddingTop: '4rem',
});

const Content = styled(Box)({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
});

const StepIndicator = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1rem',
});

const BulletPointContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    margin: '1.5rem auto',
});

const BulletPoint = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    marginBottom: '1rem',
    fontSize: '1.2rem',
    justifyContent: 'flex-start',
});

const NextButton = styled(Button)({
    backgroundColor: '#12c099',
    color: '#fff',
    fontSize: '1.4rem',
    padding: '14px 0',
    minWidth: '250px',
    width: '100%',
    maxWidth: '400px',
    borderRadius: '6px',
    marginTop: '2rem',
    '&:hover': {
        backgroundColor: '#0f9f85',
    },
});

const Footer = styled(Box)({
    width: '100%',
    padding: '2rem 0',
    textAlign: 'center',
    fontSize: '0.9rem',
    color: '#b0b0b0',
    borderTop: '1px solid #303030',
    backgroundColor: '#141414',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const LandingPage = () => {
    const navigate = useNavigate();
    const { currentUser, loading, logout } = useAuth(); // Get auth state

    // Prevent rendering if auth is still loading
    if (loading) return null;

    return (
        <>
            <NavigationBar isLoggedIn={!!currentUser} showDashboardLink={!!currentUser} handleLogout={logout} />

            <Root>
                <Content>
                    <StepIndicator>
                        <CheckCircleIcon style={{ marginRight: '0.5rem', color: '#12c099', fontSize: '2rem' }} />
                        <Typography variant="h5">Step 1 of 3</Typography>
                    </StepIndicator>

                    <Typography variant="h4" gutterBottom>
                        Choose your plan.
                    </Typography>

                    <BulletPointContainer>
                        <BulletPoint>
                            <CheckCircleIcon style={{ color: '#12c099' }} />
                            Train in person, every week, near you.
                        </BulletPoint>

                        <BulletPoint>
                            <CheckCircleIcon style={{ color: '#12c099' }} />
                            A proven system that builds elite athletes.
                        </BulletPoint>

                        <BulletPoint>
                            <CheckCircleIcon style={{ color: '#12c099' }} />
                            Show up, level up, stand out.
                        </BulletPoint>
                    </BulletPointContainer>

                    <NextButton onClick={() => navigate('/choose-plan')}>
                        Next
                    </NextButton>
                </Content>

                <Footer>
                    <Typography variant="body2">
                        © 2025 Mars Reel Media Corp. All rights reserved.
                    </Typography>
                </Footer>
            </Root>
        </>
    );
};

export default LandingPage;
