import React from 'react';
import { AppBar, Toolbar, Button, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const LogoContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
});

const Logo = styled('img')({
    height: 'auto',
    width: 'auto',
    maxWidth: '100px',
    minWidth: '50px',
});

const BetaLabel = styled(Typography)({
    marginLeft: 8,
    color: '#12c099', // Matching the green color scheme
    fontWeight: 'bold',
    fontSize: '0.9rem',
    textTransform: 'uppercase',
});

const Spacer = styled(Box)({
    flexGrow: 1,
});

const NavigationBar = ({ isLoggedIn, showDashboardLink, handleLogout }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleLogin = () => {
        navigate('/login');
    };

    const handleDashboard = () => {
        navigate('/dashboard');
    };

    const handleLogoutClick = () => {
        handleLogout();
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <AppBar position="fixed" style={{ backgroundColor: 'rgba(20, 20, 20, 0.8)' }}>
            <Toolbar>
                <LogoContainer onClick={handleLogoClick}>
                    <Logo
                        src="https://d3dbfxrvl5y81z.cloudfront.net/academy_white.png"
                        alt="MarsReel"
                    />
                    <BetaLabel>Beta</BetaLabel>
                </LogoContainer>
                <Spacer />
                {isLoggedIn ? (
                    <>
                        {showDashboardLink && (
                            <Button color="inherit" onClick={handleDashboard}>
                                Dashboard
                            </Button>
                        )}
                        <Button color="inherit" onClick={handleLogoutClick}>
                            Logout
                        </Button>
                    </>
                ) : (
                    <Button color="inherit" onClick={handleLogin}>
                        Login
                    </Button>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default NavigationBar;
